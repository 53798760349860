<template>
	<div>
		<div class="js-copy js-content pd_body" @scroll="handleScroll">
			<topHeader></topHeader>
			<div class="ac_slogan">
				<div class="ac_slogan_title">MetaEra 会务</div>
			</div>

			<div class="ac_banner">
				<div class="ac_slogan_subtitle">去中心化的链接， 全球化的视野。</div>
				<div class="ac_banner_content">
					<el-carousel :interval="0" arrow="always">
						<el-carousel-item v-for="(item, index) in bannerArray" :key="index">
							<div class="ac_banner_wrapper" @click="showBannerDetail(item.id)">
								<img :src="item.pic" :alt="item.alt" class="ac_banner_image" />
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div>
					<ac_jgq></ac_jgq>
				</div>
			</div>

			<div class="ac_calendar_wrapper">
				<div class="ac_calendar">
					<div class="ac_calendar_header">
						<div class="ac_calendar_header_top">
							<div class="ac_calendar_header_date">
								<div
									class="ac_calendar_header_top_month"
									@click="handleClick"
									ref="trigger"
								>
									{{ getYearMonth(selDate) }}
								</div>
								<el-date-picker
									class="ac_calendar_date pointer"
									v-model="selDate"
									type="month"
									ref="datePicker"
									:editable="false"
									:clearable="false"
									placeholder="选择日期"
									:popper-class="'custom-date-picker-popper'"
									@change="handleChange"
								>
									<!-- 使用 teleport 插槽来隐藏输入框 -->
								</el-date-picker>
								<div
									class="ac_calendar_header_top_left pointer"
									@click="changeDate(-1)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/5_1.png"
										class="ac_calendar_header_top_img"
									/>
								</div>
								<div
									class="ac_calendar_header_top_right pointer"
									@click="changeDate(1)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/4_1.png"
										class="ac_calendar_header_top_img"
									/>
								</div>
							</div>
							<div class="ac_calendar_header_today pointer" @click="getToday()"
								>Today</div
							>
						</div>
						<div class="ac_calendar_header_week">
							<div class="ac_calendar_header_week_item">SUN</div>
							<div class="ac_calendar_header_week_item">MON</div>
							<div class="ac_calendar_header_week_item">TUE</div>
							<div class="ac_calendar_header_week_item">WED</div>
							<div class="ac_calendar_header_week_item">THU</div>
							<div class="ac_calendar_header_week_item">FRI</div>
							<div class="ac_calendar_header_week_item">SAT</div>
						</div>
					</div>
					<div class="ac_calendar_content">
						<el-calendar v-model="selDate">
							<template v-slot:dateCell="{ date }">
								<div
									:class="getDateClass(date, selDate)"
									@click="handleDateClick(date)"
								>
									<div style="width: 100%">
										<div class="ac_calendar_date">{{ date.getDate() }}</div>
									</div>
									<div
										class="ac_calendar_task"
										v-if="getDateNum(date, selDate) > 0"
									>
										<!-- 在这里添加你的自定义内容 -->
										<span class="ac_calendar_task_num">{{
											getDateNum(date, selDate)
										}}</span>
										<span> Event</span>
									</div>
								</div>
							</template>
						</el-calendar>
					</div>
				</div>
				<div class="ac_calendar_right">
					<div class="ac_recommend">
						<div
							class="ac_recommend_list"
							v-for="(item, index) in getCurrentCalendar()"
							:key="index"
						>
							<div
								class="ac_calendar_aclist_item pointer ac_calendar_aclist_item_recommend"
								@click="tipShow(item)"
								v-if="index < 4"
							>
								<div class="ac_calendar_aclist_item_content">
									<div class="ac_calendar_aclist_item_img">
										<img v-lazy="item.pic" :key="item.pic" />
										<div class="ac_calendar_start_hour">
											{{
												item.mainevent
													? item.mainevent
													: formatTime(item.start_time)
											}}
										</div>
										<div
											class="ac_calendar_line"
											:class="{
												ac_calendar_line_noStart: item.startStatus == 0,
											}"
											v-if="item.startStatus == 0"
										>
											<div> </div>
											活动尚未开始
										</div>
										<div
											class="ac_calendar_line"
											:class="{ ac_calendar_line_end: item.startStatus == 2 }"
											v-if="item.startStatus == 2"
										>
											<div> </div>
											活动已结束
										</div>
										<div
											class="ac_calendar_line"
											:class="{
												ac_calendar_line_progress: item.startStatus == 1,
											}"
											v-if="item.startStatus == 1"
										>
											<div></div>
											活动正在进行中
										</div>
									</div>
									<div
										class="ac_calendar_aclist_item_title ac_calendar_aclist_item_title_1"
										>{{ item.title }}</div
									>
									<div class="ac_calendar_aclist_item_info">
										<div class="ac_calendar_aclist_item_area">
											<img
												src="https://zy.metaera.media/assets/images/active/6.png"
											/>
											<span>
												{{ item.address ? item.address : "暂无地址" }}
											</span>
										</div>
										<div class="ac_calendar_aclist_item_date">
											<img
												src="https://zy.metaera.media/assets/images/active/7.png"
											/>
											<span
												>{{ formatDate(item.start_time) }}
												{{ item.timeZone }}</span
											>
										</div>
									</div>
									<div class="ac_calendar_aclist_item_people">
										<div class="ac_calendar_aclist_item_organizer">
											<span>ORGANIZER：</span>
											<img :src="getHostArray(item).pic" />
											<span>{{ getHostArray(item).name }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="dots" v-if="getNumberOfGroups().length > 1">
				<div
					class="dot"
					v-for="(item, index) in getNumberOfGroups()"
					:key="index"
					:class="{ active: index === currentIndex }"
					@mouseover="hoverDot(index)"
				>
				</div>
			</div>
			<div class="ac_calendar_aclist">
				<div class="ac_calendar_aclist_bg" v-if="mainList.length > 0">
					<div class="ac_calendar_aclist_title">Events Collection</div>
					<div
						style="
							height: 2px;
							background: white;
							margin-top: 10px;
							margin-bottom: 20px;
						"
					>
					</div>
					<div class="ac_mainside">
						<el-carousel :interval="3000" arrow="always">
							<el-carousel-item v-for="(item, index) in mainList" :key="index">
								<div class="ac_mainside_item pointer" @click="tipShow(item)">
									<div class="ac_mainside_item_left">
										<div class="ac_mainside_item_tag">ME 活动合集</div>
										<div class="ac_mainside_item_title">{{ item.title }}</div>
										<div class="ac_mainside_item_num">
											{{ item.count }} Side Events
										</div>
										<div class="ac_mainside_item_show pointer">
											<span>查看活动合集</span>
											<img
												src="https://zy.metaera.media/assets/images/home/arrow_white_left.png"
											/>
										</div>
									</div>
									<div class="ac_mainside_item_right">
										<img v-lazy="item.pic" :key="item.pic" />
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div
						style="
							height: 2px;
							background: white;
							margin-top: 20px;
							margin-bottom: 20px;
						"
					></div>
				</div>
				<div class="ac_calendar_aclist_bg">
					<div class="ac_calendar_aclist_title">More Events</div>
					<div class="ac_calendar_filter_header">
						<div>活动筛选</div>
						<div @click="clearFilter()" style="font-size: 14px">清除筛选</div>
					</div>
					<div class="ac_calendar_filter_time" @click="calendarShow = true">
						<span>日期</span>
						<span>{{ this.startTime.slice(0, 10) }}</span>
						<span>-</span>
						<span>{{ this.endTime.slice(0, 10) }}</span>
						<img
							src="https://zy.metaera.media/assets/images/active/右 点击展开 三角形@2x.png"
							alt=""
						/>
					</div>
					<van-calendar
						v-model="calendarShow"
						type="range"
						:min-date="minDate"
						:max-date="maxDate"
						:show-confirm="false"
						:allow-same-day="true"
						color="#333"
						@confirm="onConfirm"
					/>

					<div class="ac_category_list_wrapper">
						<div class="ac_category_list">
							<div
								v-for="(item, index) in maineventArray"
								:key="index"
								class="ac_category_list_item"
								@click="categoryClick(item)"
								:class="{
									ac_category_list_item_sel: item == categoryValue,
								}"
							>
								{{ item }}
							</div>
						</div>
					</div>

					<div class="ac_calendar_aclist_content">
						<div
							v-if="activityArray.length == 0"
							class="ac_calendar_aclist_content_empty"
							>筛选后无任何活动，请重新筛选
						</div>
						<div
							class="ac_calendar_aclist_item pointer"
							v-for="(item, index) in activityArray"
							:key="index"
						>
							<div
								class="ac_calendar_aclist_item_content"
								@click="tipShow(item)"
							>
								<div class="ac_calendar_aclist_item_img">
									<img v-lazy="item.pic" :key="item.pic" />
									<div class="ac_calendar_start_hour">{{
										item.mainevent
											? item.mainevent
											: formatTime(item.start_time)
									}}</div>
									<div
										class="ac_calendar_line"
										:class="{ ac_calendar_line_noStart: item.startStatus == 0 }"
										v-if="item.startStatus == 0"
									>
										<div> </div>
										活动尚未开始
									</div>
									<div
										class="ac_calendar_line"
										:class="{ ac_calendar_line_end: item.startStatus == 2 }"
										v-if="item.startStatus == 2"
									>
										<div> </div>
										活动已结束
									</div>
									<div
										class="ac_calendar_line"
										:class="{
											ac_calendar_line_progress: item.startStatus == 1,
										}"
										v-if="item.startStatus == 1"
									>
										<div></div>
										活动正在进行中
									</div>
								</div>
								<div class="ac_calendar_aclist_item_title">{{
									item.title
								}}</div>
								<div class="ac_calendar_aclist_item_info">
									<div class="ac_calendar_aclist_item_area">
										<img
											src="https://zy.metaera.media/assets/images/active/6.png"
										/>
										<span>{{ item.address ? item.address : "暂无地址" }}</span>
									</div>

									<div class="ac_calendar_aclist_item_date">
										<img
											src="https://zy.metaera.media/assets/images/active/7.png"
										/>
										<span
											>{{ formatDate(item.start_time) }}
											{{ item.timeZone }}</span
										>
									</div>
								</div>
								<div
									class="ac_calendar_aclist_item_people"
									v-if="item.sideevent == null || item.sideevent == ''"
								>
									<div class="ac_calendar_aclist_item_organizer">
										<span>ORGANIZER：</span>
										<img :src="getHostArray(item).pic" />
										<span>{{ getHostArray(item).name }}</span>
									</div>
								</div>

								<div class="ac_calendar_aclist_item_side" v-else>
									<div class="ac_calendar_aclist_item_side_left">
										# {{ item.sideevent_title }}</div
									>
									<div class="ac_calendar_aclist_item_side_right"
										>SideEvent</div
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="ac_calendar_article">
				<div class="ac_calendar_article_header">
					<div class="ac_calendar_aclist_title1">Event Overview</div>
				</div>
				<div class="ac_calendar_article_list">
					<div v-for="(items, indexs) in articleList" :key="indexs">
						<wzitemnew class="wzitemnew" :item="items"></wzitemnew>
						<perNews class="perNews" :item="items"></perNews>
					</div>
				</div>
			</div>
			<bottom></bottom>
			<scrollToBottom @reachBottom="getDataListPage"></scrollToBottom>
		</div>
	</div>
</template>

<script>
import { getArticleList, acitvityLists, acitvityHome } from "@/api/home";
import topHeader from "../../components/pc/topHeader2.vue";
import bottom from "../../components/pc/bottom.vue";
import wzitemnew from "./../../components/pc/wzitemnew.vue";
import perNews from "./../home/mobile/perNews.vue";
import acTips from "./ac_tips.vue";
import ac_jgq from "./ac_jgq.vue";
import Vue from "vue";
// import Vant from "vant";
// import "vant/lib/index.css"; // 引入Vant的样式文件
// Vue.use(Vant);
import { Calendar } from "vant";
import "vant/lib/index.css"; // 引入Vant的样式文件

Vue.use(Calendar);
export default {
	name: "",
	components: {
		topHeader,
		wzitemnew,
		bottom,
		acTips,
		perNews,
		ac_jgq,
	},
	data() {
		return {
			osafai: false,
			olead: false,
			showTips: false,
			page: 1,
			size: 25,
			item: {},
			loading: false,
			articleList: [],
			selDate: new Date(),
			bannerArray: [],
			dates: [],
			activityArray: [],
			calendarActivity: [],
			mainList: [],
			currentIndex: 0,
			calendarShow: false,
			startTime: "开始日期",
			endTime: "结束日期",
			minDate: new Date("2020/01/01"),
			maxDate: new Date("2030/01/01"), // 最大日期（3个月后）
			maineventArray: [
				"全部",
				"Afterparty",
				"Meetup",
				"Summit",
				"Networking",
				"Dinner / Gala",
				"Hackathon",
				"Workshop",
				"Other",
			],
			categoryValue: "全部",
		};
	},
	created() {
		this.getAcitvityHome();
	},
	computed: {
		customFormat() {
			return (date) => {
				if (!date) return "";
				const options = { year: "numeric", month: "long" };
				return new Date(date).toLocaleDateString("zh-CN", options);
			};
		},
	},
	mounted() {
		this.preloadImage("https://zy.metaera.media/assets/images/active/13.png");
	},
	methods: {
		clearFilter() {
			this.categoryValue = "全部";
			this.startTime = "开始日期";
			this.endTime = "结束日期";
			this.getAcitvityHome();
		},
		categoryClick(item) {
			this.categoryValue = item;
			this.getAcitvityHome();
		},
		formatDate(date) {
			return `${date.getMonth() + 1}/${date.getDate()}`;
		},
		onConfirm(date) {
			const [start, end] = date;
			this.calendarShow = false;
			this.startTime = this.formatDate(start);
			this.endTime = this.formatDate(end);
			this.getAcitvityHome();
		},
		preloadImage(src) {
			const link = document.createElement("link");
			link.rel = "preload";
			link.as = "image";
			link.href = src;
			document.head.appendChild(link);
		},
		handleClick() {
			// 手动触发日期选择器的弹出
			this.$refs.datePicker.pickerVisible = true;
		},
		handleChange(value) {
			this.selDate = value;
			this.getAcitvityHome();
			console.log("Selected date:", value);
		},
		getNumberOfGroups() {
			const groupSize = 4;
			const numberOfGroups = Math.ceil(
				this.calendarActivity.length / groupSize
			);
			return new Array(numberOfGroups).fill(null);
		},
		getCurrentCalendar() {
			const start = this.currentIndex * 4;
			const end = start + 4;
			return this.calendarActivity.slice(start, end);
		},
		hoverDot(index) {
			this.currentIndex = index;
		},
		formatTime(timestamp) {
			const date = new Date(timestamp);
			let hours = date.getHours();
			const minutes = date.getMinutes();
			const ampm = hours >= 12 ? "PM" : "AM";
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			const strMinutes = minutes < 10 ? "0" + minutes : minutes;
			return hours + ":" + strMinutes + " " + ampm;
		},
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			});
		},
		getHostArray(item) {
			if (item.organizer && item.organizer.length > 10) {
				return JSON.parse(item.organizer)[0];
			} else {
				return { pic: "", name: "" };
			}
		},
		showBannerDetail(id) {
			if (id == 167) {
				window.open("https://www.metaera.hk/CryptoFi", "_blank");
				return;
			}
			if (id == 142) {
				window.open("https://www.metaerasummit.com", "_blank");
				return;
			}

			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "/ac_detail",
				params: { id: id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
		tipClose() {
			this.showTips = false;
		},
		tipShow(item) {
			this.item = item;
			// this.showTips = true;
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "/ac_detail",
				params: { id: this.item.id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
		showACDetail() {
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "/ac_detail",
				params: { id: this.item.id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
		handleScroll(event) {},
		showUrl(url) {
			window.open(url, "");
		},
		getDateClass(date, selDate) {
			if (
				date.getMonth() === selDate.getMonth() &&
				date.getDate() === selDate.getDate()
			) {
				return "selDay-date";
			} else if (date.getMonth() !== selDate.getMonth()) {
				return "noMonth-date";
			} else {
				let has = 0;
				for (let index = 0; index < this.dates.length; index++) {
					const element = this.dates[index];
					let tmpDate = new Date(element.day);
					if (date.getDate() == tmpDate.getDate()) {
						has = tmpDate.total_activities;
					}
				}
				if (has == 0) {
					return "noData-date";
				} else {
					return "numDay-date";
				}
			}
		},

		getDateNum(date, selDate) {
			if (date.getMonth() !== selDate.getMonth()) {
				return "0 ";
			} else {
				let has = "0 ";
				for (let index = 0; index < this.dates.length; index++) {
					const element = this.dates[index];
					let tmpDate = new Date(element.day);
					if (date.getDate() == tmpDate.getDate()) {
						has = element.total_activities;
					}
				}
				return has;
			}
		},
		handleDateClick(date) {
			console.log(date);
			console.log(this.selDate);
			this.currentIndex = 0;
			let has = 0;
			for (let index = 0; index < this.dates.length; index++) {
				const element = this.dates[index];
				let tmpDate = new Date(element.day);
				if (date.getDate() == tmpDate.getDate()) {
					console.log(tmpDate.getDate());
					this.calendarActivity = element.lists;
					has = element.total_activities;
				}
			}
			if (has == 0) {
				this.calendarActivity = this.bannerArray;
			}
		},

		getToday() {
			this.selDate = new Date();
			this.getAcitvityHome();
		},
		changeDate(index) {
			const year = this.selDate.getFullYear();
			const month = this.selDate.getMonth() + index;
			this.selDate = new Date(year, month, this.selDate.getDate());
			this.getAcitvityHome();
		},
		getYearMonth(date) {
			const year = this.selDate.getFullYear();
			const month = this.selDate.getMonth();
			const monthNames = [
				"一月",
				"二月",
				"三月",
				"四月",
				"五月",
				"六月",
				"七月",
				"八月",
				"九月",
				"十月",
				"十一月",
				"十二月",
			];
			return year + " " + monthNames[month];
		},
		getArticleList(obj) {
			let res = JSON.parse(sessionStorage.getItem("getArticleList3Data"));
			if (res != null && res.code == 200) {
				this.selarticleList = res.data.list.splice(0, 5);
			}
			getArticleList(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						if (obj.page == 1) {
							this.articleList = res.data.list;
							sessionStorage.setItem(
								"getArticleList3Data",
								JSON.stringify(res)
							);
						} else {
							this.articleList = this.articleList.concat(res.data.list);
						}
						this.total = res.data.total;
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.$message({ message: error.message, type: "error" });
					return;
				});
		},
		getAcitvityHome() {
			let obj = {
				year: this.selDate.getFullYear(),
				month: this.selDate.getMonth() + 1,
				otherEvent: 1,
			};
			if (this.startTime != "开始日期" && this.endTime != "结束日期") {
				obj = {
					startTime: this.startTime,
					endTime: this.endTime,
					otherEvent: 1,
				};
			}

			if (this.categoryValue != "全部") {
				obj.categoryValue = this.categoryValue;
			}
			if (this.locationValue != "全部") {
				obj.locationValue = this.locationValue;
			}

			acitvityHome(obj)
				.then((res) => {
					if (res.code == 200) {
						this.bannerArray = res.data.activityList;
						this.dates = res.data.dates;
						this.mainList = res.data.mainAllList;
						let has = 0;
						for (let index = 0; index < this.dates.length; index++) {
							const element = this.dates[index];
							let tmpDate = new Date(element.day);
							if (this.selDate.getDate() == tmpDate.getDate()) {
								this.activityArray = element.lists;
								has = element.total_activities;
							}
						}
						if (has == 0) {
							this.activityArray = this.bannerArray;
						}
						this.activityArray = res.data.lists;
						this.handleDateClick(this.selDate);
					} else {
						this.$message({ message: res.message, type: "error" });
					}
					this.getArticleList({
						category_id: 0,
						type: 7,
						page: this.page,
						size: this.size,
					});
				})
				.catch((error) => {
					this.$message({ message: error.message, type: "error" });
					this.getArticleList({
						category_id: 0,
						type: 7,
						page: this.page,
						size: this.size,
					});
					return;
				});
		},
		getDataListPage() {
			if (this.loading) {
				return;
			}
			if (this.page >= Math.ceil(this.total / this.size)) {
				return;
			}
			this.loading = true;
			this.page = this.page + 1;
			this.getArticleList({
				category_id: 0,
				type: 7,
				page: this.page,
				size: this.size,
			});
		},
	},
};
</script>
<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: black;
}
</style>

<!-- slogan -->
<style scoped>
.ac_slogan {
	width: 100%;
	margin-top: 140px;
}

.ac_slogan_title {
	display: none;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 103px;
	font-weight: 600;
	line-height: 121px;
	text-align: center;
	align-items: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

@media screen and (max-width: 900px) {
	.ac_slogan {
		margin-top: 60px;
	}

	.ac_slogan_title {
		font-size: 53px;
		line-height: 61px;
	}
}
</style>

<!-- banner -->
<style scoped>
.ac_banner {
	margin-top: 10px;
	padding-bottom: 90px;
	width: 100%;
	background: url(https://zy.metaera.media/assets/images/active/13.png);
	background-size: cover;
	background-position: center;
	overflow: hidden;
	position: relative; /* 为了让伪元素相对于这个容器定位 */
}
.ac_banner::before {
	content: "";
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 0) 20%,
		rgba(0, 0, 0, 0) 80%,
		rgba(0, 0, 0, 1) 100%
	);
	pointer-events: none; /* 让伪元素不影响子元素的交互 */
}

.ac_slogan_subtitle {
	display: none;
	margin-top: 0px;
	margin-bottom: 80px;
	font-family: Inter;
	font-size: 24px;
	font-weight: normal;
	line-height: 31.2px;
	text-align: center;
	align-items: center;
	letter-spacing: 0em;
	position: relative;
	z-index: 1;
	color: rgba(255, 255, 255, 0.7);
}
.ac_banner_content {
	height: calc(80vw * (9 / 16));
	background: white;
	border-radius: 20px;
	/* padding-bottom: 90px; */
}

.ac_banner_wrapper {
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	width: 100%;
	height: 100%;
}

.ac_banner_image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover; /* 确保图片在容器中完整显示 */
	display: block;
	border-radius: 20px;
}

@media screen and (max-width: 900px) {
	.ac_banner {
		padding-bottom: 50px;
	}
	.ac_slogan_subtitle {
		margin-top: 0px;
		margin-bottom: 20px;
		font-size: 16px;
	}
	.ac_banner_content {
		width: 90%;
		height: calc(80vw * (9 / 16));
		margin-left: 5%;
		background: white;
		border-radius: 20px;
	}
}
</style>

<!-- ac_calendar_wrapper -->
<style>
@media screen and (max-width: 900px) {
	.ac_calendar_wrapper {
		display: none !important;
	}
	.ac_calendar {
		margin-left: 5% !important;
		width: 90% !important;
		height: 580px !important;
	}

	.ac_calendar_right {
		width: 100% !important;
	}
	.ac_recommend {
		margin-left: 10%;
		width: 80%;
	}
	.ac_recommend_list {
		flex: 1 0 100% !important;
		max-height: 600px !important;
	}
	.ac_calendar_aclist_item_recommend {
		height: auto !important;
	}

	.ac_calendar_header_date {
		margin-left: 0px;
		width: 60px;
	}

	.ac_calendar_header_date .el-date-editor.el-input,
	.el-date-editor.el-input__inner {
		width: 60px;
	}

	.ac_calendar_header_date .ac_calendar_header_top_month,
	.ac_calendar_date .el-input__inner {
		margin-left: 0px !important;
		margin-right: 0px !important;
		width: 60px;
		font-size: 14px !important;
		padding-left: 0px;
		padding-right: 0px;
	}
}
.ac_calendar_wrapper {
	display: flex;
}

.ac_calendar_right {
	width: 40%;
}

.ac_recommend {
	display: flex;
	flex-wrap: wrap;
}

.dots {
	margin-left: 56%;
	margin-right: 9%;
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ccc;
	margin: 0 5px;
	cursor: pointer;
}

.dot.active {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}
.ac_recommend_list {
	flex: 1 0 43%;
	box-sizing: border-box;
	flex-grow: 0;
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	max-height: 400px;
}
</style>
<!-- calendar -->
<style>
.ac_calendar {
	border-radius: 20px;
	margin-left: 10%;
	width: 44%;
	margin-right: 2%;
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.4) 0%,
		rgba(255, 255, 255, 0.2) 30%,
		rgba(255, 255, 255, 0.05) 100%
	);
	height: 650px;
}

.ac_calendar_header {
	width: 100%;
	margin-left: 0%;
	height: 130px;
}
.ac_calendar_header_top {
	width: 100%;
	height: 61px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ac_calendar_header_top_left {
	margin-left: 30px;
}

.ac_calendar_header_top_left,
.ac_calendar_header_top_right {
	width: 20px;
	height: 20px;
	border-radius: 2px;
	margin-top: -8px;
	margin-right: 20px;
}

.ac_calendar_header_top_left img,
.ac_calendar_header_top_right img {
	width: 20px;
	height: 20px;
	border-radius: 2px;
}

.ac_calendar_header_top_month {
	display: none !important;
}

.ac_calendar_header_top_month,
.ac_calendar_date .el-input__inner {
	margin-left: 20px;
	margin-right: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 26px;
	font-weight: normal;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_header_date {
	display: flex;
	align-items: center;
	margin-left: 20px;
}

.ac_calendar_header_today {
	margin-right: 20px;
	width: 80px;
	height: 30px;
	border-radius: 6px;

	/* 333 */
	background: rgba(51, 51, 51, 0.5);

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	align-items: center;
	align-content: center;
	letter-spacing: 0em;
	text-align: center;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_header_week {
	margin-left: 0%;
	padding-right: 20px;
	padding-left: 20px;
	margin-top: 25px;
	display: flex;
	justify-content: space-around;
}

.ac_calendar_header_week_item {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	text-align: center;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_content {
	width: 100%;
	margin-left: 0%;
}
.ac_calendar .el-calendar {
	border: 0px solid #ddd;
	border-radius: 8px;
	/* margin-top: 30px; */
	background-color: rgba(255, 255, 255, 0);
}

.ac_calendar .el-calendar__header {
	display: none;
}

.ac_calendar .el-calendar__body {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 0px;
	padding-bottom: 40px;
}

.ac_calendar .el-calendar-table {
	width: 100%;
	border-collapse: collapse;
}

.ac_calendar .el-calendar-table thead {
	display: none;
}
.ac_calendar .el-calendar-table td {
	border: 0px solid #ddd;
	text-align: center;
}

.ac_calendar .el-calendar-table .el-calendar-day {
	padding: 0px;
	height: 80px;
}

.ac_calendar .el-calendar-table tr td:first-child {
	border-left: 0;
}

.ac_calendar .el-calendar-table tr:first-child td {
	border-top: 0;
}
.ac_calendar .el-calendar-table td.today {
	background-color: #42b983;
	color: #fff;
}

.ac_calendar .el-calendar-table td.disabled {
	color: #ccc;
}
.custom-date-cell {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.custom-content {
	margin-top: 5px;
	font-size: 12px;
	color: #666;
}

.ac_calendar_date {
	width: 100%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(12px, calc(100vw * 14 / 1280), 14px);
	font-weight: normal;
	line-height: 24px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	text-align: center;
	color: #ffffff;
}
.ac_calendar_task {
	margin-top: 10px;
}

.ac_calendar_task_num {
	margin-right: 4px;
}
.ac_calendar_task,
.ac_calendar_task span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(6px, calc(100vw * 10 / 1280), 14px);

	font-weight: normal;
	line-height: 16px;
	align-items: center;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

.ac_calendar .noData-date {
	height: 100%;
	padding-top: 18px;
	padding-bottom: 18px;
	background-color: rgba(255, 255, 255, 0);
	border-radius: 10px;
	opacity: 1;
	box-sizing: border-box;
}
.ac_calendar .noData-date .ac_calendar_date {
	color: #aaaaaa;
}

.ac_calendar .noData-date .ac_calendar_task {
	color: #aaaaaa;
}

.ac_calendar .noMonth-date {
	height: 100%;
	padding-top: 18px;
	padding-bottom: 18px;
	border-radius: 10px;
	box-sizing: border-box;
}

.ac_calendar .noMonth-date .ac_calendar_date {
	color: #ffffff;
}

.ac_calendar .noMonth-date .ac_calendar_task {
	color: #ffffff;
}

.el-calendar-table td.is-selected,
.el-calendar-table .el-calendar-day:hover {
	background-color: rgba(255, 255, 255, 0);
}
.ac_calendar .selDay-date {
	height: 100%;
	padding-top: 18px;
	padding-bottom: 18px;
	border-radius: 10px;
	opacity: 1;
	/* 纯白 */
	background: #494949;
	box-sizing: border-box;
	/* 纯白 */
}

.ac_calendar .numDay-date {
	height: 100%;

	padding-top: 18px;
	padding-bottom: 18px;
	border-radius: 10px;
	box-sizing: border-box;
}

.ac_calendar .numDay-date .ac_calendar_date {
	color: #ffffff;
}

.ac_calendar .numDay-date .ac_calendar_task {
	color: #000000;
}

@media screen and (max-width: 900px) {
	.ac_calendar .el-calendar-table .el-calendar-day {
		padding: 5px;
		height: 80px;
	}

	.ac_calendar_date {
		width: 100%;
		font-size: 12px;
		line-height: 14px;
		display: inline-block;
		align-content: center;
		text-align: center;
		align-items: center;
	}
	.ac_calendar .noMonth-date {
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		padding: 0px;
	}

	.ac_calendar .numDay-date {
		padding: 0px;
		border-radius: 4px;
		opacity: 0.5;
		/* 主要色/主要色背景 */
		border: 1px solid #e0eaff;
	}

	.ac_calendar .selDay-date {
		padding: 0px;
		border-radius: 4px;
		/* 纯白 */
		border: 1px solid #ffffff;
	}

	.ac_calendar_task {
		display: table-cell;
		margin-top: 0px;
	}

	.ac_calendar_task span {
		display: inline-block;
		text-align: center;
		align-content: center;
		align-items: center;
		font-size: 10px;
	}

	.ac_calendar_header {
		width: 100%;
		margin-left: 0%;
		height: 80px;
	}

	.ac_calendar_header_top {
		height: 45px;
	}

	.ac_calendar_header_week {
		margin-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.ac_calendar_header_top_month {
		font-size: 18px;
	}
	.ac_calendar_content {
		width: 100%;
		margin-left: 0%;
	}

	.ac_calendar .el-calendar__body {
		margin-top: 10px;
		padding: 10px;
		padding-top: 20px;
	}
	.ac_calendar .el-calendar {
		margin-top: 10px;
	}
}
</style>

<!-- ac_calendar_aclist -->
<style scoped>
.ac_calendar_aclist {
	width: 100%;
	/* margin-top: 90px; */
	/* background: url(https://zy.metaera.media/assets/images/active/12.png); */
	background-size: cover;
	background-position: center;
	overflow: hidden;
}
.ac_calendar_aclist_bg {
	width: 80%;
	margin-left: 10%;
	margin-top: 90px;
}

.ac_calendar_aclist_title {
	font-family: "Bison-Bold";
	font-size: 60px;
	line-height: 60px;
	font-weight: 400;
	letter-spacing: 0em;
	text-align: center;
	align-content: center;
	color: #ffffff;

	/* font-variation-settings: "opsz" auto;


	text-decoration: underline;
	text-decoration-color: #0256ff;
	text-decoration-thickness: 4px;
	text-underline-offset: 10px; */
}

.ac_calendar_aclist_title1 {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: black;
	text-decoration: underline;
	text-decoration-color: #0256ff; /* 设置下划线颜色 */
	text-decoration-thickness: 4px; /* 设置下划线高度 */
	text-underline-offset: 10px; /* 调整下划线与文字的距离 */
}
.ac_calendar_aclist_date {
	margin-top: 40px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 24px;
	font-weight: 400;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_content {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 60px;
	margin-left: -2%;
	width: 104%;
}

.ac_calendar_aclist_content_empty {
	width: 100%;
	margin-top: 20px;
	color: white;
	font-size: 14px;

	text-align: center;
}
.ac_calendar_aclist_item {
	margin-top: 50px;
	margin-left: 1.6%;
	margin-right: 1.6%;
	flex: 1 0 30%;
	box-sizing: border-box;
	flex-grow: 0;
	border-radius: 20px;
	position: relative;
	overflow: hidden;
	padding: 12px;

	background: linear-gradient(
		135deg,
		rgba(255, 255, 255, 0.47) 0%,
		rgba(255, 255, 255, 0) 112%
	);
}

.ac_calendar_aclist_item_recommend {
	margin-top: 0px;
	flex: 1 0 40%;
	height: 100%;
}

.ac_calendar_aclist_item::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.6);
	border-radius: 20px;
	z-index: 0;
}

.ac_calendar_aclist_item_content {
	position: relative;
	z-index: 1;
}

.ac_calendar_aclist_item_img {
	position: relative;

	border-radius: 10px;
	/* padding: 12px; */
}

.ac_calendar_aclist_item_img img {
	width: 100%;
	border-radius: 10px;
	aspect-ratio: 16 / 9;
	object-fit: cover;
}

.ac_calendar_start_hour {
	top: 10px; /* 距离顶部的距离 */
	left: 10px; /* 距离左边的距离 */
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
	color: white; /* 字体颜色 */
	border-radius: 7px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	display: none;
}

.ac_calendar_line {
	top: 10px; /* 距离顶部的距离 */
	right: 10px; /* 距离右边的距离 */
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
	color: white; /* 字体颜色 */
	border-radius: 7px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_line_noStart {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: #0256ff;
}

.ac_calendar_line_end {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: gray;
}

.ac_calendar_line_noStart div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}

.ac_calendar_line_progress {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: #08bf66;
}
.ac_calendar_line_progress div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: #08bf66;
}

.ac_calendar_aclist_item_title {
	margin-top: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(14px, calc(100vw * 16 / 1280), 18px);
	font-weight: 400;
	line-height: 30px;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	margin-right: 10px;
}

.ac_calendar_aclist_item_title_1 {
	-webkit-line-clamp: 1;
}
.ac_calendar_aclist_item_info {
	margin-top: 6px;
	display: block;
	justify-content: space-between;
}
.ac_calendar_aclist_item_area,
.ac_calendar_aclist_item_date {
	margin-top: 6px;
}

.ac_calendar_aclist_item_side {
	margin-top: 15px;
	display: inline-flex;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 20px;
	opacity: 1;
	box-sizing: border-box;
	border: 1px solid #ffffff;
}

.ac_calendar_aclist_item_side_left,
.ac_calendar_aclist_item_side_right {
	font-size: clamp(10px, calc(100vw * 12 / 1280), 14px);
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff !important;
}

.ac_calendar_aclist_item_side_left {
	/* width: 60%; */
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	margin-right: 10px;
}

.ac_calendar_aclist_item_side_right {
	/* width: 40%; */
}

.ac_calendar_aclist_item_info span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 12 / 1280), 14px);
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_item_info img {
	width: auto;
	height: 15px;
	margin-right: 10px;
}

.ac_calendar_aclist_item_people {
	margin-top: 8px;
	display: inline-flex;
	justify-content: space-between;

	padding-top: 4px;
	padding-bottom: 8px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 20px;
	opacity: 1;
	box-sizing: border-box;
	border: 1px solid #ffffff;
}
.ac_calendar_aclist_item_people span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 10px;
	font-weight: normal;
	line-height: 10px;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_item_people img {
	margin-left: 10px;
	width: 18px;
	height: 18px;
	margin-right: -10px;
	border-radius: 9px;
	object-fit: cover;
}

.ac_calendar_aclist_item_organizer img {
	margin-right: 4px;
}

@media screen and (max-width: 900px) {
	.ac_calendar_aclist {
		margin-top: 10px;
	}
	.ac_calendar_aclist_bg {
		width: 90%;
		margin-left: 5%;
		margin-top: 20px;
	}
	.ac_calendar_aclist_title {
		font-size: 20px;
		line-height: 30px;
		text-decoration-thickness: 2px; /* 设置下划线高度 */
		text-underline-offset: 6px; /* 调整下划线与文字的距离 */
	}

	.ac_calendar_aclist_date {
		margin-top: 20px;
		font-size: 18px;
	}

	.ac_calendar_aclist_content {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 60px;
		margin-left: -0%;
		width: 100%;
	}

	.ac_calendar_aclist_item {
		margin-top: 30px;
		margin-left: 0%;
		margin-right: 0%;
		flex: 1 0 100%;
		border-radius: 20px;
		padding: 12px;
	}

	.ac_calendar_aclist_item_info img {
		width: auto;
		height: clamp(10px, calc(100vw * 12 / 414), 14px);
		margin-right: 6px;
	}
}
</style>

<!-- ac_calendar_article -->
<style scoped>
.ac_calendar_article {
	width: 100%;
	background: white;
	padding-bottom: 80px;
}

.ac_calendar_article_header {
	margin-left: 10%;
	margin-right: 10%;
	padding-top: 60px;
	display: flex;
}
.ac_calendar_article_header_1 {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.ac_calendar_article_header_2 {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	border-radius: 4px;
	opacity: 1;

	/* 主要色/主要色 */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}
.ac_calendar_article_list {
	margin-left: 10%;
	margin-top: 20px;
	width: 80%;
}
.wzitemnew {
	display: flex;
}
.perNews {
	display: none;
}
@media screen and (max-width: 900px) {
	.wzitemnew {
		display: none;
	}
	.perNews {
		display: contents;
	}
	.ac_calendar_article_header {
		margin-left: 5%;
		width: 90%;
		padding-top: 40px;
	}
	.ac_calendar_article_list {
		margin-left: 5%;
		width: 90%;
	}

	.ac_calendar .numDay-date .ac_calendar_task {
		color: #000000;
		display: grid;
	}
}
</style>
<style>
@media screen and (max-width: 900px) {
	.ac_banner_content .el-carousel__container {
		width: 100% !important;
		height: calc(80vw * (9 / 16)) !important;
		margin: 0 !important;
	}
}

.ac_mainside {
	margin-top: 20px;
}

@media screen and (max-width: 900px) {
	.ac_mainside .el-carousel__container {
		height: calc(80vw * (0.5)) !important;
	}
}
.ac_calendar_date .el-input__prefix {
	display: none;
}
.ac_calendar_date .el-input__inner {
	background: rgba(0, 0, 0, 0);
	border: 0 solid rgba(0, 0, 0, 0);
	color: #ffffff;
}
.ac_calendar_date {
	background: rgba(0, 0, 0, 0);
}
</style>

<style scoped>
.ac_mainside_item {
	display: flex;
	height: calc(80vw * (0.2)) !important;
	justify-content: space-between;
}

.ac_mainside_item_left {
	margin-left: clamp(60px, calc(100vw * 40 / 1280), 60px);
}

.ac_mainside_item_tag {
	margin-top: clamp(6px, calc(100vw * 10 / 1280), 20px);
	width: clamp(100px, calc(100vw * 120 / 1280), 140px);
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 20px;
	opacity: 1;
	/* 纯白 */
	background: #ffffff;
	font-size: clamp(14px, calc(100vw * 16 / 1280), 18px);
	font-weight: bold;
	line-height: 16px;
	text-align: center;
	display: block;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 一级文字 */
	color: #333333;
	text-align: center;
	align-items: center;
	align-content: center;
}

.ac_mainside_item_title {
	margin-top: clamp(6px, calc(100vw * 10 / 1280), 20px);
	font-size: clamp(20px, calc(100vw * 30 / 1280), 50px);
	font-weight: bold;
	line-height: clamp(30px, calc(100vw * 40 / 1280), 60px);
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_mainside_item_num {
	margin-top: clamp(12px, calc(100vw * 14 / 1280), 20px);
	font-size: clamp(12px, calc(100vw * 16 / 1280), 20px);
	font-weight: 500;
	line-height: clamp(14px, calc(100vw * 16 / 1280), 10px);
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_mainside_item_show {
	width: 170px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 20px;
	opacity: 1;
	box-sizing: border-box;
	border: 1.5px solid #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-top: clamp(12px, calc(100vw * 16 / 1280), 20px);
}

.ac_mainside_item_show span {
	margin-left: 10px;
	font-size: clamp(14px, calc(100vw * 16 / 1280), 18px);
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.ac_mainside_item_show img {
	width: 16px;
	height: 16px;
	object-fit: cover;
	margin-right: 10px;
}
.ac_mainside_item_right {
	height: 100%;
	aspect-ratio: 16 / 9;
	margin-right: 60px;
	margin-left: 0%;
}

.ac_mainside_item_right img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

@media screen and (max-width: 900px) {
	.ac_mainside_item_left {
		display: none;
	}
	.ac_mainside_item {
		display: block;
		height: calc(80vw * (0.5)) !important;
	}
	.ac_mainside_item_right {
		margin-left: 10%;
		margin-right: 0px;
	}
	.dots {
		margin-left: 0%;
		margin-right: 0%;
		display: flex;
		justify-content: center;
		margin-top: 30px;
		display: none;
	}
}
</style>

<style scoped>
.ac_calendar_filter_header {
	margin-top: 20px;
	color: white;
	font-size: 16px;
	font-weight: 600;
	display: flex;
	justify-content: space-between;
}
.ac_calendar_filter_time {
	margin-top: 20px;
}
.ac_calendar_filter_time span {
	color: white;
	font-size: 14px;
	font-weight: 600;
	margin-right: 20px;
}
.ac_category_list_wrapper {
	margin-top: 10px;
	width: 100%; /* 容器宽度 */
	overflow-x: auto; /* 启用横向滚动 */
	white-space: nowrap; /* 防止子元素换行 */
	-webkit-overflow-scrolling: touch; /* 平滑滚动，适配移动端 */
}

.ac_category_list {
	display: flex; /* 子元素横向排列 */
	gap: 10px; /* 按钮之间的间距 */
	padding-top: 10px;
	padding-bottom: 10px;
}

.ac_category_list_item {
	height: 20px;
	padding-left: 10px;
	padding-right: 10px;
	background: gray;
	border-radius: 10px;
	text-align: center;
	align-content: center;
	align-items: center;
	font-size: 12px;
}

.ac_category_list_item_sel {
	background: white;
}
</style>
